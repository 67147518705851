import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Chart1 from './Chart1';
import TotalRevenue from './totalRevenue';
import Commission from './Commission';
//import Tree from './treeView';
import Detail from './Detail';
import AccountMenu from './Menu';
import {useAuthValue} from './AuthContext';
import Copyright from './footer';
import UserCount from'./userCount';
import TotalPt from './totalPt';
import UserData from './userData';
import TotalRevenueAll from './totalRevenueAll';
import TotalPtAll from './totalPtAll';
import CommissionAll from './CommissionAll';

function Dashboard() {
const {currentUser} = useAuthValue();

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== '',
  })(({ theme,  }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  }));

const mdTheme = createTheme();

 
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              ダッシュボード
            </Typography>         
            <AccountMenu/>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              
              {/* Tree */}
              <Grid item xs={12} md={3} lg={3} >
                
                <Grid sx={{ mb: 3}}>
                  <UserData/>
                </Grid>
                <Grid  sx={{ mb: 3}}>
                  <UserCount/>
                </Grid>
              </Grid>
             

              {/* Chart */}
              <Grid item xs={12} md={9} lg={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <TotalRevenue />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TotalPt />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Commission />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TotalRevenueAll />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TotalPtAll />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <CommissionAll />
                </Grid>

                <Grid item xs={12} md={12} lg={12} >
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Chart />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12} >
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Chart1 />
                    </Paper>
                </Grid>
              </Grid>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}


export default  Dashboard