import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AccountMenu from './Menu';
import Copyright from './footer';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';

function PaymentList() {
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== '',
})(({ theme,  }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

}));

const mdTheme = createTheme();



// Generate Order Data
function createData(id, month, date, amount, paymentDetail) {
  return { id, month, date, amount, paymentDetail };
}

const rows = [
  createData(
    0,
    'データなし',
    'データなし',
    'データなし',
  ),
];

function preventDefault(event) {
  event.preventDefault();
}


  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              sx={{ ml: 1}}
              variant="contained"
              color="inherit"
              size="large"
              component={Link}
              to="/"
            >
              <ArrowBackIos/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              支払いリスト
            </Typography>         
            <AccountMenu/>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} >
                <React.Fragment>
                <Paper sx={{ padding: 2 }} >
                  <Title>支払明細</Title>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>対象月</TableCell>
                          <TableCell>支払日</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.month}</TableCell>
                          <TableCell>{row.date}</TableCell>
                          <TableCell align="right">
                            <Button variant="contained" color="primary" component={Link} to='/detail' align="right">詳細</Button>
                          </TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </React.Fragment>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default PaymentList