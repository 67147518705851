import * as React from 'react';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import {useState, useEffect} from 'react'
import {useAuthValue} from './AuthContext';


function TotalRevenue() {

  const {currentUser} = useAuthValue();
  const [agentId, setAgentId]=useState();
  const [Getdata, setGetdata] =useState();
  const [Getlm, setGetLm] =useState();
  var date1 = new Date();
  var date2 = new Date();
  
  date1.setMonth(date1.getMonth());
  date1.setDate(0);

  useEffect(() => {
    const getUser = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email":currentUser.email,
        })
    }
    let isMounted = true
    const fetchData = async () => {
      const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
      const GetUser = await res.json()
      const agentId = GetUser.data[0].agentId
      setAgentId(agentId)
      const getRevenues = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "agentId":agentId,
          "startDate":(date2.getFullYear())+"/"+(date2.getMonth()+1)+"/1/00:00:00",
          "endDate":(date2.getFullYear())+"/"+(date2.getMonth()+1)+"/"+(date2.getDate()+"/"+(date2.getHours())+":"+(date2.getMinutes())+":"+(date2.getSeconds())),
          "isAll": false
        })
      }
      const getRevenues1 = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "agentId":agentId,
          "startDate":(date1.getFullYear())+"/"+(date1.getMonth()+1)+"/1/00:00:00",
          "endDate":(date1.getFullYear())+"/"+(date1.getMonth()+1)+"/"+(date1.getDate()+"/23:59:59"),
          "isAll": false
        })
      }
      const res1 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/totalRevenues', getRevenues)
      const Getdata = await res1.json()
      Number(Getdata)
      const res2 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/totalRevenues', getRevenues1)
      const GetLm = await res2.json()
      Number(GetLm)
      const Getlm = ((Getdata.data[0].listPrice-GetLm.data[0].listPrice)/GetLm.data[0].listPrice*100)
      if (isMounted) { 
      setGetdata(Getdata)
      setGetLm(Getlm)
    }
  }
    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  const theme = createTheme({
    palette: {
      background: {
        paper: '#03a9ea',
      },
      text: {
        primary: '#fffbf2',
        secondary: '#eceded',
        tertiary: '#ffffff',

      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 5,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box sx={{ color: 'text.tertiary', fontSize: 20,fontWeight: 'medium' }}>当月売上</Box>
        <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
          { Getdata ? Getdata.data[0].listPrice +' 円' : <p>Now loading...</p>}
        </Box>
        <Box
          sx={{
            color: 'success.dark',
            display: 'inline',
            fontWeight: 'bold',
            mx: 0.5,
            fontSize: 14,
          }}
        >
          {Getlm}%
        </Box>
        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>
          vs. last month
        </Box>
      </Box>
    </ThemeProvider>
  );

}
export default TotalRevenue