import React, { useContext,useEffect,useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AccountMenu from './Menu';
import {useAuthValue} from './AuthContext';
import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import Copyright from "./footer";
import toast, { Toaster } from 'react-hot-toast'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';


function Profile() {

const {currentUser} = useAuthValue();
const [email, setEmail] = useState('')
const [password, setPassword] = useState('') 
const [parentId, setParentId]=useState()
const [agentName, setAgentName]=useState()
const [tel, setTel]=useState()
const [bankName, setBankName]=useState()
const [bankBranch, setBankBranch]=useState()
const [bankAccount, setBankAccount]=useState()
const [lastName, setLastName]=useState()
const [firstName, setFirstName]=useState()
const [age, setAge]=useState()
const [zip, setZip]=useState()
const [country, setCountry]=useState()
const [state, setState]=useState()
const [city, setCity]=useState()
const [street1, setStreet1]=useState()
const [street2, setStreet2 ]=useState()
const [mobile, setMobile]=useState()
const [chkTerms, setChkTerms]=useState()
const [chkTransferApproval, setChkTransferApproval]=useState()
const [chkAdult, setChkAdult]=useState()
const [chkQualification, setChkQualification]=useState()
const [uuid, setUuid]=useState()
const [agentId, setAgentId]=useState()
const [gender, setGender]=useState()


useEffect(() => {
const getUser = {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "email":currentUser.email,
    })
  }
  let isMounted = true
  const fetchData = async () => {
    const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
    const Getdata = await res.json()

    const email = Getdata.data[0].email
    const passward = Getdata.data[0].passsward
    const parentId = Getdata.data[0].parentId
    const agentName = Getdata.data[0].agentName
    const tel = Getdata.data[0].tel
    const bankName = Getdata.data[0].bankName
    const bankBranch = Getdata.data[0].bankBranch
    const bankAccount = Getdata.data[0].bankAccount
    const lastName = Getdata.data[0].lastName
    const firstName = Getdata.data[0].firstName
    const age = Getdata.data[0].age
    const zip = Getdata.data[0].zip
    const country = Getdata.data[0].country
    const state = Getdata.data[0].state
    const city = Getdata.data[0].city
    const street1 = Getdata.data[0].street1
    const street2 = Getdata.data[0].street2
    const mobile = Getdata.data[0].mobile
    const chkTerms = Getdata.data[0].chkTerms
    const chkTransferApproval = Getdata.data[0].chkTransferApproval
    const chkAdult = Getdata.data[0].chkAdult
    const chkQualification = Getdata.data[0].chkQualification
    const uuid = Getdata.data[0].uuid
    const agentId = Getdata.data[0].agentId
    const gender = Getdata.data[0].gender

    if (isMounted) { 

    setEmail(email)
    setPassword(password)
    setParentId(parentId)
    setAgentName(agentName)
    setTel(tel)
    setBankName(bankName)
    setBankBranch(bankBranch)
    setBankAccount(bankAccount)
    setLastName(lastName)
    setFirstName(firstName)
    setAge(age)
    setZip(zip)
    setCountry(country)
    setState(state)
    setCity(city)
    setStreet1(street1)
    setStreet2(street2)
    setMobile(mobile)
    setChkTerms(chkTerms)
    setChkTransferApproval(chkTransferApproval)
    setChkAdult(chkAdult)
    setChkQualification(chkQualification)
    setUuid(uuid)
    setAgentId(agentId)
    setGender(gender)

  }
  return () => {
    isMounted = false
  }
}
  fetchData()
}, [])

  const Send = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: currentUser.email,
      password: currentUser.password,
      parentId: parentId,
      agentName: agentName,
      tel: tel,
      bankAccount: bankAccount,
      bankBranch: bankBranch,
      bankName: bankName,
      firstName: firstName,
      lastName: lastName,
      age: age,
      zip: zip,
      country: country,
      state: state,
      city: city,
      street1: street1,
      street2: street2,
      mobile: mobile,
      chkTerms: chkTerms,
      chkTransferApproval: chkTransferApproval,
      chkAdult: chkAdult,
      chkQualification: chkQualification,
      gender:gender,
      uuid: ""
    })
  }
  

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== '',
  })(({ theme,  }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  }));

const mdTheme = createTheme();

 
  return (
    <ThemeProvider theme={mdTheme}>
      <Toaster position="top-right" duration="4000" />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              sx={{ ml: 1}}
              variant="contained"
              color="inherit"
              size="large"
              component={Link}
              to="/"
            >
              <ArrowBackIos/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              プロフィール
            </Typography>
            <AccountMenu/>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='email'
                        fullWidth 
                        value={currentUser.email}
                        disabled
                        label="Email Address"
                        autoComplete="email"
                        placeholder="Enter your Email Address"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setEmail(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='password'
                        fullWidth
                        value={currentUser.password}
                        disabled
                        label="Password"
                        autoComplete=""
                        placeholder='Enter your Password'
                        autoFocus
                        defaultValue={'　　　　　　　　'}
                        onChange={e => setPassword(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={parentId}
                        required
                        disabled
                        label="親ID"
                        autoComplete="ID"
                        placeholder="親ID"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setParentId(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={agentName}
                        required
                        disabled
                        label="表示名称"
                        autoComplete="Name"
                        placeholder="表示名称を入力"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setAgentName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='tel'
                        fullWidth 
                        value={tel}
                        required
                        disabled
                        label="電話番号"
                        autoComplete="tel"
                        placeholder="電話番号を入力"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setTel(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='tel'
                        fullWidth 
                        value={mobile}
                        required
                        disabled
                        label="携帯電話番号"
                        autoComplete="tel"
                        placeholder="携帯電話番号"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setMobile(e.target.value)}/>
                     </Grid>
                  </Grid>
                </Paper>
              </Grid>
              
              <Grid item xs={12} md={8} lg={8}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={bankName}
                        required
                        disabled
                        label="銀行名"
                        autoComplete=""
                        placeholder="銀行名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setBankName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={bankBranch}
                        required
                        disabled
                        label="支店名"
                        autoComplete=""
                        placeholder="支店名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setBankBranch(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={bankAccount}
                        required
                        disabled
                        label="口座番号"
                        autoComplete=""
                        placeholder="口座番号"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setBankAccount(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={lastName}
                        required
                        disabled
                        label="姓"
                        autoComplete="LastName"
                        placeholder="姓"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setLastName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={firstName}
                        required
                        disabled
                        label="名"
                        autoComplete="FirstName"
                        placeholder="名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setFirstName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>    
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={age}
                        required
                        disabled
                        label="年齢"
                        autoComplete="age"
                        placeholder="年齢"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setAge(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={zip}
                        required
                        disabled
                        label="郵便番号"
                        autoComplete="zipcode"
                        placeholder="郵便番号"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setZip(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>  
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={country}
                        required
                        disabled
                        label="国"
                        autoComplete="country"
                        placeholder="国"
                        autoFocus
                        defaultValue={"　"}
                        selected
                        onChange={e => setEmail(e.target.value)}
                      >
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>  
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={state}
                        required
                        disabled
                        label="都道府県"
                        autoComplete="state"
                        placeholder="都道府県"
                        autoFocus
                        defaultValue={"　"}
                        selected
                        onChange={e => setState(e.target.value)}
                      >                    
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={city}
                        required
                        disabled
                        label="市区町村"
                        autoComplete="city"
                        placeholder="市区町村"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setCity(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={street1}
                        required
                        disabled
                        label="番地"
                        autoComplete="street1"
                        placeholder="番地"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setStreet1(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={street2}
                        disabled
                        label="建物名"
                        autoComplete="street2"
                        placeholder="建物名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setStreet2(e.target.value)}/>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Copyright sx={{ pt: 2 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}


export default  Profile