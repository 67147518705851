import React, { useContext,useEffect,useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AccountMenu from './Menu';
import {useAuthValue} from './AuthContext';
import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import Copyright from "./footer";
import toast, { Toaster } from 'react-hot-toast'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import Title from './Title';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import getProducts from './getProducts.json';
import Modal from '@mui/material/Modal';
import SendIcon from '@mui/icons-material/Send';

function NewOrder() {

const {currentUser} = useAuthValue();
const [agentId, setAgentId]=useState()


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

var date = new Date();

useEffect(() => {
const getUser = {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "email":currentUser.email,
    })
  }
  const getP ={
    method: "GET"
  }
  let isMounted = true
  const fetchData = async () => {
    const res1 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
    const Getdata = await res1.json()
    //const res2 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/getProducts', getP)
    //const GetProducts = await res2.json()
    const agentId = Getdata.data[0].agentId
    

    if (isMounted) { 

    setAgentId(agentId)
    //setId(id)
    //setListPrice(listPrice)
    //setCostPrice(costPrice)
    //setPt(pt)
    //setProductName(productName)


    const cdList = [];
    const Len = getProducts.data.length
    for (let i =0; i < Len; i++) {
        //for (var  in getProducts.data[NaN]) {
            const cdStr = <div>
                               {getProducts.data[NaN].id + ',' + getProducts.data[NaN].productName + ',' }
                          </div>;
            cdList.push(cdStr);
        //}
    }
console.log(cdList)
  }
  return () => {
    isMounted = false
  }
}
  fetchData()
}, [])

  const Send = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      revenueAt: (date.getFullYear())+"/"+(date.getMonth()+1)+"/"+(date.getDate()),
      productId: "1",
      status: "",
      agentId: agentId,
      storeId: "",
      listPrice: "",
      costPrice: "",
      pt: "",
      memo: "",
    })
  }
  
  async function SendFetch() {
    const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/revenueAdd', Send)
    const SendData = await res.json()
    const notify = () => toast.success('データを送信しました。');
    const notify1 =() => toast.error('データの送信が失敗しました。');
    SendData.data.serverStatus="2"?notify() :notify1()
    console.log(SendData)
  }
 

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== '',
  })(({ theme,  }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  }));

const mdTheme = createTheme();

function createData(id, orderId, date, amount, status, approval) {
    return { id, orderId, date, amount, status, approval  };
  }
  
  const rows = [
    createData(
      'id',
      'データなし',
      'データなし',
      'データなし',
      'データなし',
    ),
  ];
 
  return (
    <ThemeProvider theme={mdTheme}>
      <Toaster position="top-right" duration="4000" />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              sx={{ ml: 1}}
              variant="contained"
              color="inherit"
              size="large"
              component={Link}
              to="/orderlist"
            >
              <ArrowBackIos/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              新規オーダー
            </Typography>
            <AccountMenu/>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Title>オーダー入力</Title>
                  <Grid container spacing={1}>
                  <Table size="small">
                        <TableHead>
                          <TableRow>
                          <TableCell>商品名</TableCell>
                          <TableCell>単価</TableCell>
                          <TableCell>個数</TableCell>
                          <TableCell>金額</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.approval}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Box
                sx={{ pt: 2,
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}  
            >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleOpen}
                >
                  確認する
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            オーダー確認
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            入力した個数でオーダーします。
                        </Typography>
                        <Box
                            sx={{ pt: 2,
                            justifyContent: 'flex-end',
                            display: 'flex',
                            }}  
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                endIcon={<SendIcon />}
                                onClick={() => SendFetch()}
                            >
                                オーダーする
                            </Button>
                        </Box>
                    </Box>
                </Modal>
              </Box>
            <Copyright sx={{ pt: 2 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}


export default  NewOrder