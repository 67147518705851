import {useState} from 'react'
import './forms.css'
import {auth} from './firebase'
import {useHistory, Link} from 'react-router-dom'
import {sendPasswordResetEmail, sendEmailVerification} from 'firebase/auth'
import {useAuthValue} from './AuthContext'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'


function Pwreset() {

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const history = useHistory()
  const {setTimeActive} = useAuthValue()

  const pwreset = e => {
    e.preventDefault()
    setError('')

      // Create a new user with email and password using firebase
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setTimeActive(true)
          history.push('/reset-pw')
          .catch((err) => alert(err.message))
        })
        .catch(err => setError(err.message))

    setEmail('')

  }

  return (
    <div className='center'>
      <div className='auth'>
      <Typography component="h1" variant="h4" color="primary">
        パスワードリセット
      </Typography>
      <Typography variant="subtitle2" color="warning">  
          パスワードをリセットするメールアドレスを入力してください。
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={pwreset} name='pwreset_form'>
        {error && <div className='auth__error'>{error}</div>}
          <TextField 
            type='email' 
            value={email}
            placeholder="メールアドレスを入力してください"
            label="メールアドレス"
            onChange={e => setEmail(e.target.value)}/>

          <Button variant="outlined" type='submit'>パスワードリセット</Button>

        <Typography variant="subtitle2" color="warning">  
          <Link to='/login'>ログインに戻る</Link>
        </Typography>
        </Box>
      </div>
    </div>
  )
}

export default Pwreset