import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import {auth} from './firebase'
import {useHistory} from 'react-router-dom'
import {useAuthValue} from './AuthContext'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


function Login(){

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 
  const [error, setError] = useState('')
  const {setTimeActive} = useAuthValue()
  const history = useHistory()

  const login = e => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      if(!auth.currentUser.emailVerified) {
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setTimeActive(true)
          history.push('/verify-email')
        })
      .catch(err => alert(err.message))
    }else{
      history.push('/')
    }
    })
    .catch(err => setError(err.message))
  }
  const theme = createTheme();

  return(
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
      
      <Typography component="h1" variant="h4" color="primary">
        ZOOO Supporter ADMIN
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={login} name='login_form'>
        {error && <div className='auth__error'>{error}</div>}
          <TextField
            margin="normal" 
            type='email'
            fullWidth 
            value={email}
            required
            label="メールアドレス"
            autoComplete="email"
            placeholder="メールアドレスを入力してください。"
            autoFocus
            onChange={e => setEmail(e.target.value)}/>

          <TextField
            margin="normal" 
            type='password'
            fullWidth
            value={password}
            required
            label="パスワード"
            autoComplete="current-password"
            placeholder='パスワードを入力してください。'
            onChange={e => setPassword(e.target.value)}/>

          <Button 
            variant="contained" 
            type='submit'
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            ログイン
          </Button>
        <Typography variant="body1" color="info" align="center">
          アカウントをお持ちでない方 
        </Typography>
        <Typography variant="subtitle2" color="warning" align="center">  
          <Link to='/register'>アカウントを新規作成する</Link>
        </Typography>
        <Typography variant="body1" color="info" align="center">
          パスワードを忘れた方 
        </Typography>
        <Typography variant="subtitle2" color="warning" align="center">  
          <Link to='/pwreset'>パスワードリセット</Link>
        </Typography>
        </Box>
        </Box>
        </Grid>
        </Grid>
    </ThemeProvider>
  )
}

export default Login