import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import PaidIcon from '@mui/icons-material/Paid';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import {useAuthValue} from './AuthContext';
import Typography from '@mui/material/Typography';
import { Route,Link,Switch } from 'react-router-dom';
import { Button } from '@mui/material';


export default function AccountMenu() {
  const {currentUser} = useAuthValue();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
        <Typography sx={{ minWidth: 100 }}>{currentUser.email}</Typography>
        <Tooltip title="ユーザー情報">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color='inherit'
          >
            <Avatar />
          </IconButton>
        </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>     
          <Button
            component={Link}
            to="/profile"
          >  
            <Avatar/> Profile
          </Button>
        </MenuItem>
        <Divider />
        {/* <MenuItem>     
          <Button
            component={Link}
            to="/orderlist"
          >  
             <FormatListBulleted /> Order list
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem>     
          <Button
            component={Link}
            to="/paymentlist"
          >  
             <PaidIcon /> payment list
          </Button>
        </MenuItem>
        <Divider /> */}
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Button onClick={() => signOut(auth)}>Logout</Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
