import {useState} from 'react'
import './forms.css'
import {auth} from './firebase'
import {useHistory, Link} from 'react-router-dom'
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import {useAuthValue} from './AuthContext'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { yupResolver } from '@hookform/resolvers';
import * as Yup from "yup";

function Register() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const history = useHistory()
  const {setTimeActive} = useAuthValue()

  const validatePassword = () => {
    let isValid = true
    if (password !== '' && confirmPassword !== ''){
      if (password !== confirmPassword) {
        isValid = false
        setError('入力されたパスワードが一致しません')
      }
    }
    return isValid
  }


  const register = e => {
    e.preventDefault()
    setError('')
    if(validatePassword()) {
      // Create a new user with email and password using firebase
        createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          sendEmailVerification(auth.currentUser)   
          .then(() => {
            setTimeActive(true)
            history.push('/verify-email')
          }).catch((err) => alert(err.message))
        })
        .catch(err => setError(err.message))
    }
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }

  return (
    <div className='center'>
      <div className='auth'>
      <Typography component="h1" variant="h4" color="primary">
        新規登録
      </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={register} name='registration_form'>
        {error && <div className='auth__error'>{error}</div>}
          <TextField 
            type='email' 
            value={email}
            placeholder="メールアドレスを入力してください"
            label="メールアドレス"
            required
            
            onChange={e => setEmail(e.target.value)}/>
            

          <TextField 
            type='password'
            value={password} 
            required
            label="パスワード"
            placeholder='パスワードを入力してください'
            onChange={e => setPassword(e.target.value)}/>

          <TextField 
            type='password'
            value={confirmPassword} 
            required
            label="パスワード（確認）"
            placeholder='パスワードを再度入力してください'
            onChange={e => setConfirmPassword(e.target.value)}/>

          <Button variant="outlined" type='submit'>新規登録</Button>

        <Typography variant="body2" color="info">
          アカウントを既に作成済みの方
        </Typography>
        <Typography variant="subtitle2" color="warning">  
          <Link to='/login'>ログインページ</Link>
        </Typography>
        </Box>
      </div>
    </div>
  )
}

export default Register