import React, { useState, useEffect } from "react";
import { PDFDocument, rgb, degrees, PageSizes} from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';
import { Button } from "@mui/material";
import {useAuthValue} from './AuthContext';
import { Link } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AccountMenu from './Menu';
import Copyright from './footer';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';

function CreatePdf() {
  
  const {currentUser} = useAuthValue();
  const [pdf, setPdf] = useState(null);
  const [agentId, setAgentId]=useState();
  const [AgentUQName, setAgentUQName] =useState();
  const [agentName, setAgentName]=useState()
  const [Cm1, setCm1] =useState(0);
  const [Rm1, setRm1] =useState(0);

  var date0 = new Date();
  var date1 = new Date();

  date1.setMonth(date1.getMonth());
  date1.setDate(0);

  useEffect(() => {
    getPdf().then(_pdf => setPdf(_pdf));
  }, []);

  useEffect(() => {
    const getUser = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email":currentUser.email,
        })
    }
    const Com = async () => {
      const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
      const GetUser = await res.json()
      const agentId = GetUser.data[0].agentId
      const AgentUQName = GetUser.data[0].agentUQName
      const agentName = GetUser.data[0].agentName
      Number(GetUser)
      setAgentId(agentId)
      setAgentUQName(AgentUQName)
      setAgentName(agentName)
      const lM = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "agentId":agentId,
          "startDate":(date1.getFullYear())+"/"+(date1.getMonth()+1)+"/1/00:00:00",
          "endDate":(date1.getFullYear())+"/"+(date1.getMonth()+1)+"/"+(date1.getDate()+"/23:59:59"),
          "isAll": false
        })
      }
      const res1 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/commission', lM)
      const Cm1 = await res1.json()
      Number(Cm1)
      const res2 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/totalRevenues', lM)
      const Rm1 = await res2.json()
      Number(Rm1)

      setCm1(Cm1)
      setRm1(Rm1)      
    }
    Com()
  }, [])

  async function getPdf() {
  const pdfDoc = await PDFDocument.create();
  const url = './SawarabiGothic-Regular.ttf';
  const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

  pdfDoc.registerFontkit(fontkit)
  const customFont = await pdfDoc.embedFont(fontBytes)
  // Add a blank page to the document
  const page = pdfDoc.addPage(PageSizes.A4);

  // Get the width and height of the page
  const { width, height } = page.getSize();

  const jpgUrl = './logo.jpg'
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
  
  const jpgImage = await pdfDoc.embedJpg(jpgImageBytes)
  const jpgDims = jpgImage.scale(0.2)

  const pngUrl = './trihikostamp.png'
  const pngImageBytes = await fetch(pngUrl).then((res1) => res1.arrayBuffer())
  
  const pngImage = await pdfDoc.embedPng(pngImageBytes) 
  const pngDims = pngImage.scale(1)
  pdfDoc.setTitle('支払明細書', { showInWindowTitleBar: true })

  // Draw a string of text toward the top of the page

  page.drawImage(jpgImage, {
    x: 30,
    y: height - jpgDims.height-25,
    width: jpgDims.width,
    height: jpgDims.height,
    rotate: degrees(0),
  })
  page.drawImage(pngImage, {
    x: 488,
    y: height - pngDims.height-189,
    width: pngDims.width,
    height: pngDims.height,
    rotate: degrees(0),
  })

  const text1 ="支払明細書"
  const text1Width = customFont.widthOfTextAtSize(text1, 30)
  page.drawText(text1, {
    x: page.getWidth() / 2 - text1Width / 2,
    y: height - 100,
    size: 30,
    font: customFont,
    color: rgb(0, 0, 0),
  });
  
  

  page.drawText("ユーザー名", {
    x: 45,
    y: height - 230,
    size: 15,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("御中", {
    x: 215,
    y: height - 230,
    size: 15,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawLine({
    start: { x: 30, y: height - 235 },
    end: { x: 250, y: height - 235 },
    thickness: 2,
  });

  page.drawText("発行日 : "+(date0.getFullYear())+"/"+(date0.getMonth()+1)+"/1", {
    x: 435,
    y: height - 120,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("ユーザーID : ", {
    x: 435,
    y: height - 140,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });

  page.drawText("トリヒコ株式会社", {
    x: 389,
    y: height - 195,
    size: 15,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("〒135-0064", {
    x: 390,
    y: height - 215,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("東京都江東区青海2-7-4", {
    x: 390,
    y: height - 230,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("theSOHO 1123", {
    x: 432,
    y: height - 245,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("TEL : 050-3629-2100", {
    x: 390,
    y: height - 260,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });

  page.drawText("下記の通り登録口座にお支払いします。", {
    x: 40,
    y: height - 265,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("登録口座 : ", {
    x: 40,
    y: height - 315,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });

  page.drawText("対象年月", {
    x: 52,
    y: height - 367,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("売上金額(円)", {
    x: 158,
    y: height - 367,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("売上ポイント(Pt)", {
    x: 300,
    y: height - 367,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("コミッションポイント(Pt)", {
    x: 425,
    y: height - 367,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("2022/02", {
    x: 52,
    y: height - 392,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("200000", {
    x: 170,
    y: height - 392,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("1000000", {
    x: 320,
    y: height - 392,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("100000", {
    x: 470,
    y: height - 392,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawLine({
    start: { x: 30, y: height - 350 },
    end: { x: 565, y: height - 350 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 30, y: height - 375 },
    end: { x: 565, y: height - 375 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 400 },
    end: { x: 565, y: height - 400 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 425 },
    end: { x: 565, y: height - 425 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 450 },
    end: { x: 565, y: height - 450 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 475 },
    end: { x: 565, y: height - 475 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 500 },
    end: { x: 565, y: height - 500 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 525 },
    end: { x: 565, y: height - 525 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 550 },
    end: { x: 565, y: height - 550 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 575 },
    end: { x: 565, y: height - 575 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 30, y: height - 349 },
    end: { x: 30, y: height - 601 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 115, y: height - 349 },
    end: { x: 115, y: height - 601 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 265, y: height - 349 },
    end: { x: 265, y: height - 601 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 415, y: height - 349 },
    end: { x: 415, y: height - 601 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 565, y: height - 349 },
    end: { x: 565, y: height -601 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 30, y: height - 600 },
    end: { x: 565, y: height - 600 },
    thickness: 2,
  });

  page.drawText("対象ポイント合計", {
    x: 357,
    y: height - 641,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("100000", {
    x: 488,
    y: height - 641,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("振込金額", {
    x: 378,
    y: height - 666,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawText("100000", {
    x: 488,
    y: height - 666,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawLine({
    start: { x: 350, y: height - 625 },
    end: { x: 565, y: height - 625 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 350, y: height - 650 },
    end: { x: 565, y: height - 650 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 350, y: height - 624 },
    end: { x: 350, y: height - 676 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 450, y: height - 624 },
    end: { x: 450, y: height - 676 },
    thickness: 1.5,
  });
  page.drawLine({
    start: { x: 565, y: height - 624 },
    end: { x: 565, y: height - 676 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 350, y: height - 675 },
    end: { x: 565, y: height - 675 },
    thickness: 2,
  });

  page.drawText("備考 ", {
    x: 40,
    y: height - 745,
    size: 11,
    font: customFont,
    color: rgb(0, 0, 0)
  });
  page.drawLine({
    start: { x: 30, y: height - 725 },
    end: { x: 565, y: height - 725 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 30, y: height - 724 },
    end: { x: 30, y: height - 812 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 565, y: height - 724 },
    end: { x: 565, y: height - 812 },
    thickness: 2,
  });
  page.drawLine({
    start: { x: 30, y: height - 811 },
    end: { x: 565, y: height - 811 },
    thickness: 2,
  });

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  const blob = new Blob([pdfBytes],{type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
  }
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== '',
  })(({ theme,  }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  
  }));
  
  const mdTheme = createTheme();


  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              sx={{ ml: 1}}
              variant="contained"
              color="inherit"
              size="large"
              component={Link}
              to="/paymentlist"
            >
              <ArrowBackIos/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              支払いリスト
            </Typography>         
            <AccountMenu/>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
    <div>
      <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}>
        <Button href={pdf} download="支払明細書.pdf" style={{ margin: "auto" }} variant="contained" color="inherit">
          download
        </Button>
      </div>
      {pdf && (
        <iframe
          src={pdf}
          title="支払明細書"
          width="100%"
          height="1200"
          download="支払明細書.pdf"
        />
      )}
    </div>
    </Box>
      </Box>
    </ThemeProvider>
  );
}

export default CreatePdf;