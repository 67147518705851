import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Dashboard from './Dashboard';
import Register from './Register'
import VerifyEmail from './VerifyEmail';
import Login from './Login'
import {useState, useEffect} from 'react'
import {AuthProvider} from './AuthContext'
import {auth} from './firebase'
import {onAuthStateChanged} from 'firebase/auth'
import PrivateRoute from './PrivateRoute';
import Profile from './Profile';
import CreatePdf from './pdfDetail';
import CreateUser from './CreateUser';
import OrderList from './OrderList';
import PaymentList from './PaymentList';
import NewOrder from './NewOrder';
import Pwreset from './PwReset';
import ResetEmail from './ResetEmail';


function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  return (
    <Router>
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path='/verify-email' component={VerifyEmail} />
          <Route exact path='/reset-pw' component={ResetEmail} />
          <Route exact path="/pwreset" component={Pwreset} />
          <Route exact path='/profile' component={Profile} /> 
          <Route exact path='/detail' component={CreatePdf} />
          <Route exact path="/createuser" component={CreateUser} />
          <Route exact path="/orderlist" component={OrderList} />          
          <Route exact path="/paymentlist" component={PaymentList} />
          <Route exact path="/neworder" component={NewOrder} />
        </Switch>  
      </AuthProvider>
  </Router>
  );
}

export default App;
