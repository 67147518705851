import React, { useContext,useEffect,useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AccountMenu1 from './Menu1st';
import {useAuthValue} from './AuthContext';
import { Redirect } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import { Button ,MenuItem} from "@mui/material";
import Copyright from "./footer";
import toast, { Toaster } from 'react-hot-toast'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

function CreateUser() {

const {currentUser} = useAuthValue();
const [email, setEmail] = useState('')
const [password, setPassword] = useState('') 
const [parentId, setParentId]=useState()
const [agentName, setAgentName]=useState()
const [tel, setTel]=useState()
const [bankName, setBankName]=useState()
const [bankBranch, setBankBranch]=useState()
const [bankAccount, setBankAccount]=useState()
const [lastName, setLastName]=useState()
const [firstName, setFirstName]=useState()
const [age, setAge]=useState()
const [zip, setZip]=useState()
const [country, setCountry]=useState()
const [state, setState]=useState()
const [city, setCity]=useState()
const [street1, setStreet1]=useState()
const [street2, setStreet2 ]=useState()
const [mobile, setMobile]=useState()
const [chkTerms, setChkTerms]=useState()
const [chkTransferApproval, setChkTransferApproval]=useState()
const [chkAdult, setChkAdult]=useState()
const [chkQualification, setChkQualification]=useState()
const [uuid, setUuid]=useState()
const [agentId, setAgentId]=useState()
const [gender, setGender]=useState()

const basicSchema = Yup.object().shape({
  email: Yup.string()
      .required("必須項目です")
      .email("メールアドレスの形式が不正です"),

    password: Yup.string()
      .required("必須項目です")
      .matches(
        /^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/,
        "半角英数字記号以外は使用できません"
      )
      .min(8, "パスワードは8文字以上で入力してください"),

    parentId: Yup.string()
      .required("必須項目です")
      .matches(/^[0-9]+$/, "半角数字で入力してください"),

    agentName: Yup.string().required("必須項目です"),

    tel: Yup.string()
      .required("必須項目です")
      .matches(/^[0-9]+$/, "半角数字で入力してください")
      .min(10, "電話番号はハイフン無しで入力してください")
      .max(11, "電話番号は最大11桁です"),

    bankName: Yup.string()
      .required("必須項目です")
      .matches(/^[ぁ-んァ-ヶー一-龠]+$/, "全角で入力してください"),

    bankBranch: Yup.string()
      .required("必須項目です")
      .matches(/^[ぁ-んァ-ヶー一-龠]+$/, "全角で入力してください"),

    bankAccount: Yup.string()
      .required("必須項目です")
      .length(7, "口座番号は7桁で入力してください")
      .matches(/^[0-9]+$/, "半角数字で入力してください"),

  firstName: Yup.string().required("必須項目です"),

  lastName: Yup.string().required("必須項目です"),

  age: Yup.string()
    .required("必須項目です")
    .matches(/^[0-9]+$/, "半角数字で入力してください"),

  zip: Yup.string()
    .required("必須項目です")
    .max(7, "郵便番号はハイフン無しで入力してください")
    .matches(/^[0-9]+$/, "半角数字で入力してください"),

  country: Yup.string().required("必須項目です"),

  state: Yup.string().required("必須項目です"),

  city: Yup.string().required("必須項目です"),

  street1: Yup.string().required("必須項目です"),

  street2: Yup.string(),

  mobile: Yup.string()
    .required("必須項目です")
    .length(11, "携帯電話番号はハイフン無しで11桁で入力してください")
    .matches(/^[0-9]+$/, "半角数字で入力してください")
});

useEffect(() => {
const getUser = {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    "email":currentUser.email,
    })
  }
  let isMounted = true
  const fetchData = async () => {
    const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
    const Getdata = await res.json()

    const email = Getdata.data[0].email
    const passward = Getdata.data[0].passsward
    const parentId = Getdata.data[0].parentId
    const agentName = Getdata.data[0].agentName
    const tel = Getdata.data[0].tel
    const bankName = Getdata.data[0].bankName
    const bankBranch = Getdata.data[0].bankBranch
    const bankAccount = Getdata.data[0].bankAccount
    const lastName = Getdata.data[0].lastName
    const firstName = Getdata.data[0].firstName
    const age = Getdata.data[0].age
    const zip = Getdata.data[0].zip
    const country = Getdata.data[0].country
    const state = Getdata.data[0].state
    const city = Getdata.data[0].city
    const street1 = Getdata.data[0].street1
    const street2 = Getdata.data[0].street2
    const mobile = Getdata.data[0].mobile
    const chkTerms = Getdata.data[0].chkTerms
    const chkTransferApproval = Getdata.data[0].chkTransferApproval
    const chkAdult = Getdata.data[0].chkAdult
    const chkQualification = Getdata.data[0].chkQualification
    const uuid = Getdata.data[0].uuid
    const agentId = Getdata.data[0].agentId
    const gender = Getdata.data[0].gender

    if (isMounted) { 

    setEmail(email)
    setPassword(password)
    setParentId(parentId)
    setAgentName(agentName)
    setTel(tel)
    setBankName(bankName)
    setBankBranch(bankBranch)
    setBankAccount(bankAccount)
    setLastName(lastName)
    setFirstName(firstName)
    setAge(age)
    setZip(zip)
    setCountry(country)
    setState(state)
    setCity(city)
    setStreet1(street1)
    setStreet2(street2)
    setMobile(mobile)
    setChkTerms(chkTerms)
    setChkTransferApproval(chkTransferApproval)
    setChkAdult(chkAdult)
    setChkQualification(chkQualification)
    setUuid(uuid)
    setAgentId(agentId)
    setGender(gender)

  }
  return () => {
    isMounted = false
  }
}
  fetchData()
}, [])

  const Send = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: currentUser.email,
      password: currentUser.password,
      parentId: parentId,
      agentName: agentName,
      tel: tel,
      bankAccount: bankAccount,
      bankBranch: bankBranch,
      bankName: bankName,
      firstName: firstName,
      lastName: lastName,
      age: age,
      zip: zip,
      country: country,
      state: state,
      city: city,
      street1: street1,
      street2: street2,
      mobile: mobile,
      chkTerms: 1,
      chkTransferApproval: 1,
      chkAdult: 1,
      chkQualification: 1,
      uuid: ""
    })
  }
  
  async function SendFetch() {
    const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agentsAdd', Send)
    const SendData = await res.json()
    const notify = () => toast.success('データを送信しました。');
    const notify1 =() => toast.error('データの送信が失敗しました。');
    SendData.data.serverStatus="2"?notify() :notify1()
    console.log(SendData)
    return (
      <Redirect to="/"/>
      )
  }
 

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== '',
  })(({ theme,  }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  }));

const mdTheme = createTheme();

 
  return (
    <ThemeProvider theme={mdTheme}>
      <Toaster position="top-right" duration="4000" />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              ユーザー情報入力
            </Typography>
            <AccountMenu1/>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='email'
                        fullWidth 
                        value={currentUser.email}
                        ReadOnly
                        label="Email Address"
                        autoComplete="email"
                        placeholder="Enter your Email Address"
                        autoFocus
                        onChange={e => setEmail(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='password'
                        fullWidth
                        value={currentUser.password}
                        ReadOnly
                        label="Password"
                        autoComplete="current-password"
                        placeholder='Enter your Password'
                        autoFocus
                        onChange={e => setPassword(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={parentId}
                        required
                        label="親ID"
                        autoComplete="ID"
                        placeholder="親ID"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setParentId(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={agentName}
                        required
                        label="表示名称"
                        autoComplete="Name"
                        placeholder="表示名称を入力"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setAgentName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='tel'
                        fullWidth 
                        value={tel}
                        required
                        label="電話番号"
                        autoComplete="tel"
                        placeholder="電話番号を入力"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setTel(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        margin="normal" 
                        type='tel'
                        fullWidth 
                        value={mobile}
                        required
                        label="携帯電話番号"
                        autoComplete="tel"
                        placeholder="携帯電話番号"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setMobile(e.target.value)}/>
                     </Grid>
                  </Grid>
                </Paper>
              </Grid>
              
              <Grid item xs={12} md={8} lg={8}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={bankName}
                        required
                        label="銀行名"
                        autoComplete=""
                        placeholder="銀行名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setBankName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={bankBranch}
                        required
                        label="支店名"
                        autoComplete=""
                        placeholder="支店名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setBankBranch(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={bankAccount}
                        required
                        label="口座番号"
                        autoComplete=""
                        placeholder="口座番号"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setBankAccount(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={lastName}
                        required
                        label="姓"
                        autoComplete="LastName"
                        placeholder="姓"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setLastName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={firstName}
                        required
                        label="名"
                        autoComplete="FirstName"
                        placeholder="名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setFirstName(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>    
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={age}
                        required
                        label="年齢"
                        autoComplete="age"
                        placeholder="年齢"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setAge(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={zip}
                        required
                        label="郵便番号"
                        autoComplete="zipcode"
                        placeholder="郵便番号"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setZip(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>  
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={country}
                        required
                        label="国"
                        autoComplete="country"
                        placeholder="国"
                        autoFocus
                        defaultValue={"　"}
                        selected
                        onChange={e => setEmail(e.target.value)}
                      >
                        <MenuItem value="日本">日本</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>  
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={state}
                        required
                        label="都道府県"
                        autoComplete="state"
                        placeholder="都道府県"
                        autoFocus
                        defaultValue={"　"}
                        selected
                        onChange={e => setState(e.target.value)}
                      >
                        <MenuItem value="北海道">北海道</MenuItem>
                        <MenuItem value="青森県">青森県</MenuItem>
                        <MenuItem value="岩手県">岩手県</MenuItem>
                        <MenuItem value="宮城県">宮城県</MenuItem>
                        <MenuItem value="秋田県">秋田県</MenuItem>
                        <MenuItem value="山形県">山形県</MenuItem>
                        <MenuItem value="福島県">福島県</MenuItem>
                        <MenuItem value="茨城県">茨城県</MenuItem>
                        <MenuItem value="栃木県">栃木県</MenuItem>
                        <MenuItem value="群馬県">群馬県</MenuItem>
                        <MenuItem value="埼玉県">埼玉県</MenuItem>
                        <MenuItem value="千葉県">千葉県</MenuItem>
                        <MenuItem value="東京都">東京都</MenuItem>
                        <MenuItem value="神奈川県">神奈川県</MenuItem>
                        <MenuItem value="新潟県">新潟県</MenuItem>
                        <MenuItem value="富山県">富山県</MenuItem>
                        <MenuItem value="石川県">石川県</MenuItem>
                        <MenuItem value="福井県">福井県</MenuItem>
                        <MenuItem value="山梨県">山梨県</MenuItem>
                        <MenuItem value="長野県">長野県</MenuItem>
                        <MenuItem value="岐阜県">岐阜県</MenuItem>
                        <MenuItem value="静岡県">静岡県</MenuItem>
                        <MenuItem value="愛知県">愛知県</MenuItem>
                        <MenuItem value="三重県">三重県</MenuItem>
                        <MenuItem value="滋賀県">滋賀県</MenuItem>
                        <MenuItem value="京都府">京都府</MenuItem>
                        <MenuItem value="大阪府">大阪府</MenuItem>
                        <MenuItem value="兵庫県">兵庫県</MenuItem>
                        <MenuItem value="奈良県">奈良県</MenuItem>
                        <MenuItem value="和歌山県">和歌山県</MenuItem>
                        <MenuItem value="鳥取県">鳥取県</MenuItem>
                        <MenuItem value="島根県">島根県</MenuItem>
                        <MenuItem value="岡山県">岡山県</MenuItem>
                        <MenuItem value="広島県">広島県</MenuItem>
                        <MenuItem value="山口県">山口県</MenuItem>
                        <MenuItem value="徳島県">徳島県</MenuItem>
                        <MenuItem value="香川県">香川県</MenuItem>
                        <MenuItem value="愛媛県">愛媛県</MenuItem>
                        <MenuItem value="高知県">高知県</MenuItem>
                        <MenuItem value="福岡県">福岡県</MenuItem>
                        <MenuItem value="佐賀県">佐賀県</MenuItem>
                        <MenuItem value="長崎県">長崎県</MenuItem>
                        <MenuItem value="熊本県">熊本県</MenuItem>
                        <MenuItem value="大分県">大分県</MenuItem>
                        <MenuItem value="宮崎県">宮崎県</MenuItem>
                        <MenuItem value="鹿児島県">鹿児島県</MenuItem>
                        <MenuItem value="沖縄県">沖縄県</MenuItem>                    
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={city}
                        required
                        label="市区町村"
                        autoComplete="city"
                        placeholder="市区町村"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setCity(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={street1}
                        required
                        label="番地"
                        autoComplete="street1"
                        placeholder="番地"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setStreet1(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal" 
                        type='text'
                        fullWidth 
                        value={street2}
                        required
                        label="建物名"
                        autoComplete="street2"
                        placeholder="建物名"
                        autoFocus
                        defaultValue={'　'}
                        onChange={e => setStreet2(e.target.value)}/>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Box
                sx={{ pt: 2,
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}  >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => SendFetch()}
                >
                  登録する
                </Button>
              </Box>
            <Copyright sx={{ pt: 2 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}


export default  CreateUser