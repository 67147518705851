import './verifyEmail.css'
import {useAuthValue} from './AuthContext'
import {useState, useEffect} from 'react'
import {auth} from './firebase'
import {useHistory, Link} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {sendPasswordResetEmail} from 'firebase/auth'

function ResetEmail() {

  return (
    <div className='center'>
      <div className='verifyEmail'>
        <h1>パスワードリセット</h1>
        <p>
        <strong>パスワードリセットメールを送信しました。</strong>
        </p>
        <span>届いたメール本文のリンクにアクセスし、パスワードを変更してください。</span>
        <span>確認メールは迷惑メールフォルダに入ってしまう場合がありますので、そちらもご確認ください。</span>        
        <Typography variant="subtitle2" color="warning">  
          <Link to='/login'>ログインページに戻る</Link>
        </Typography>
      </div>
    </div>
  )
}

export default ResetEmail
