import './verifyEmail.css'
import {useAuthValue} from './AuthContext'
import {useState, useEffect} from 'react'
import {auth} from './firebase'
import {sendEmailVerification} from 'firebase/auth'
import {useHistory,Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'


function VerifyEmail() {

  const {currentUser} = useAuthValue()
  const [time, setTime] = useState(60)
  const {timeActive, setTimeActive} = useAuthValue()
  const history = useHistory()

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser?.reload()
        .then(() => {
        if(currentUser?.emailVerified){
          clearInterval(interval)
          history.push('/login')
        }
      }) 
      .catch((err) => {
        alert(err.message)
      })
    }, 1000)
  }, [history, currentUser])

  useEffect(() => {
    let interval = null
    if(timeActive && time !== 0 ){
      interval = setInterval(() => {
        setTime((time) => time - 1)
      }, 1000)
    }else if(time === 0){
      setTimeActive(false)
      setTime(60)
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive])

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
    .then(() => {
      setTimeActive(true)
    }).catch((err) => {
      alert(err.message)
    })
  }

  return (
    <div className='center'>
      <div className='verifyEmail'>
        <h1>ご登録ありがとうございます</h1>
        <p>
          <span>入力頂いたメールアドレス:</span><br/>
          <strong>{currentUser?.email}</strong>
          <span>に確認メールを送信しました。</span>
        </p>
        <span>届いたメール本文のリンクにアクセスし、登録を完了してください。</span>
        <span>確認メールは迷惑メールフォルダに入ってしまう場合がありますので、そちらもご確認ください。</span>        
        <Button 
          onClick={resendEmailVerification}
          disabled={timeActive}
          color="warning"
        >メールを再送信する {timeActive && time}</Button>
        <Typography variant="subtitle2" color="warning">  
          <Link to='/login'>ログインページに戻る</Link>
        </Typography>
      </div>
    </div>
  )
}

export default VerifyEmail
