import React from 'react';
import Title from './Title';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import { useState,useEffect } from 'react';
import {useAuthValue} from './AuthContext';

export default function Chart() { 
  const {currentUser} = useAuthValue();
  const [agentId, setAgentId]=useState();
  const [Cm1, setCm1] =useState(0);
  const [Cm2, setCm2] =useState(0);
  const [Cm3, setCm3] =useState(0);
  const [Rm1, setRm1] =useState(0);
  const [Rm2, setRm2] =useState(0);
  const [Rm3, setRm3] =useState(0);

  var date1 = new Date();
  var date2 = new Date();
  var date3 = new Date();
  
  date1.setMonth(date1.getMonth()-1);
  date1.setDate(0);
  date2.setMonth(date2.getMonth());
  date2.setDate(0);
  
  useEffect(() => {
    const getUser = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email":currentUser.email,
        })
    }
    const Com = async () => {
      const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
      const GetUser = await res.json()
      const agentId = GetUser.data[0].agentId
      setAgentId(agentId)
      const mBL = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "agentId":agentId,
          "startDate":(date1.getFullYear())+"/"+(date1.getMonth()+1)+"/1/00:00:00",
          "endDate":(date1.getFullYear())+"/"+(date1.getMonth()+1)+"/"+(date1.getDate()+"/23:59:59")
        })
      }
      const lM = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "agentId":agentId,
          "startDate":(date2.getFullYear())+"/"+(date2.getMonth()+1)+"/1/00:00:00",
          "endDate":(date2.getFullYear())+"/"+(date2.getMonth()+1)+"/"+(date2.getDate()+"/23:59:59")
        })
      }
      const tM = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "agentId":agentId,
          "startDate":(date3.getFullYear())+"/"+(date3.getMonth()+1)+"/1/00:00:00",
          "endDate":(date3.getFullYear())+"/"+(date3.getMonth()+1)+"/"+(date3.getDate()+"/"+(date3.getHours())+":"+(date3.getMinutes())+":"+(date3.getSeconds()))
        })
      }
      const res1 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/commission', mBL)
      const Cm1 = await res1.json()
      Number(Cm1)
      const res2 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/commission', lM)
      const Cm2 = await res2.json()
      Number(Cm2)
      const res3 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/commission', tM)
      const Cm3 = await res3.json()
      Number(Cm3)
      const res4 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/totalRevenues', mBL)
      const Rm1 = await res4.json()
      Number(Rm1)
      const res5 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/totalRevenues', lM)
      const Rm2 = await res5.json()
      Number(Rm2)
      const res6 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/totalRevenues', tM)
      const Rm3 = await res6.json()
      Number(Rm3)

      setCm1(Cm1)
      setCm2(Cm2)
      setCm3(Cm3)
      setRm1(Rm1)
      setRm2(Rm2)
      setRm3(Rm3)
      
    }
    Com()
  }, [])

  const data = [
    {
      name: '先々月',
      uv: Cm1?Cm1.data[0].commissionValues:<p>Nowloading</p>,
      pv: Rm1?Rm1.data[0].listPrice:<p>Nowloading</p>,
    },
    {
      name: '先月',
      uv: Cm2?Cm2.data[0].commissionValues:<p>Nowloading</p>,
      pv: Rm2?Rm2.data[0].listPrice:<p>Nowloading</p>,

    },
    {
      name: '当月',
      uv: Cm3?Cm3.data[0].commissionValues:<p>Nowloading</p>,
      pv: Rm3?Rm3.data[0].listPrice:<p>Nowloading</p>,
    },
  ];

  return (
    <React.Fragment>
      <Title>売上/コミッション</Title>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" barSize={20} fill="#8884d8" name="売上"/>
          <Line type="monotone" dataKey="uv" stroke="#ff3399" name="コミッション"/>
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}