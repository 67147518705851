import * as React from 'react';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import {useState, useEffect} from 'react'
import {useAuthValue} from './AuthContext';

function UserData() {
  const {currentUser} = useAuthValue();
  const [AgentUQName, setAgentUQName] =useState();
  const [StatusName, setStatusName] =useState();
  const [AgentName, setAgentName] =useState();
  
  
  useEffect(() => {
    const getUser = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email":currentUser.email,
        })
    }
    let isMounted = true
    const fetchData = async () => {
      const res1 = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
      const GetUser = await res1.json()
      const AgentUQName = GetUser.data[0].agentUQName
      const StatusName = GetUser.data[0].statusName
      const AgentName = GetUser.data[0].agentName
      setAgentUQName(AgentUQName)
      setStatusName(StatusName)
      setAgentName(AgentName)

      if (isMounted) { 

    }
  }
    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  const theme = createTheme({
    palette: {
      background: {
        paper: '#eceded',
      },
      text: {
        primary: '#171717',
        secondary: '#2c2c2c',

      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 5,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box sx={{ color: 'text.secondary', fontSize: 20,fontWeight: 'medium' }}>ユーザー情報</Box>
        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>ユーザーID:</Box>
        <Box sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'medium' }}>
          { AgentUQName ? AgentUQName : <p>Now loading...</p>}
        </Box>
        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>ユーザーネーム:</Box>
        <Box sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'medium' }}>
          { AgentName ? AgentName : <p>Now loading...</p>}
        </Box>
        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>ランク:</Box>
        <Box sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'medium' }}>
          { StatusName ? StatusName : <p>Now loading...</p>}
        </Box>
      </Box>
    </ThemeProvider>
  );

}
export default UserData