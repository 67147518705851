import {Route, Redirect,Link} from 'react-router-dom'
import {useAuthValue} from './AuthContext'
import {useState, useEffect} from 'react'

export default function PrivateRoute({component:Component, ...rest}) {
  const {currentUser} = useAuthValue()
  const [agentId, setAgentId]=useState()

  useEffect(() => {
    const getUser = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email":currentUser.email,
        })
    }
    let isMounted = true
    const fetchData = async () => {
      const res = await fetch ('https://us-central1-zooo-agent.cloudfunctions.net/api/agent/0', getUser)
      const GetUser = await res.json()
      const agentId = GetUser.data[0].agentId
      Number(agentId)
      //const agentId = agentid ? agentid:<p>Now loading...</p>
      setAgentId(agentId)      
      return () => {
        isMounted = false
      }
    }
    fetchData()
  }, [])
  console.log(agentId)
  return (
    <Route
      {...rest}
      render={props => {
        return currentUser?.emailVerified? <Component {...props} />: <Redirect to='/login' />
        
    }}>
    </Route>
  )
}